
.task-icon{
  margin-right: 16px;
  margin-left: 8px;
}

@media (min-width: 410px){
  .task-icon{
    margin-right: 8px;
    margin-left: 0;
  }
}

@media (min-width: 540px){
  .task-icon{
    margin-right: 0;
  }
}
