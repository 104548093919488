
@media (max-width: 639px) {
  .hide-top{
    display: none !important;
  }

  .file-options{
    position: absolute;
  }

  .task-check::after{
    content: "task";
    font-family: 'Oswald', sans-serif;
  }
}
