.search-button{
  background: $darker;
  padding:8px 15px;
  border: none;
  color:grey;
  border-radius: 0 .25rem .25rem 0;
  &:hover{
    background: $blue;
    color:white;
  }
}

.search-bar-lighter{
  padding:8px 15px;
  background: $dark;
  border: none;
  width:100%;
  border-radius: .25rem 0 0 .25rem;
}

.search-button-lighter{
  background: $dark;
  padding:8px 15px;
  border: none;
  color:grey;
  border-radius: 0 .25rem .25rem 0;
  &:hover{
    background: $blue;
    color:white;
  }
}

.search-bar-white{
  padding:8px 15px;
  background: white;
  color: grey;
  border: none;
  width:100%;
  border-radius: .25rem 0 0 .25rem;
}

.search-button-blue{
  background: $blue;
  padding:8px 15px;
  border: none;
  color:white;
  border-radius: 0 .25rem .25rem 0;
  &:hover{
    background: darken($blue, 10);
    color:white;
  }
}
