// move to _colors
$light-grey: grey;
$dark-grey: #323232;

$green: #90c42f;
$orange: #f0a815;
$blue: #1790d4;

$dark: #323232;
$darker: #232323;
$lighter: #a6a6a6;

$mainHighlight: $green;
$secondaryHighlight: $orange;

@import "./files/_files.scss";
@import "./needs/__needsStyles.scss";
@import "./plan/_treatmentPlan.scss";
@import "./contacts/_sharing.scss";

// tachyons-like rules
.bg-deepgrey {
  background-color: $dark-grey;
}

.b--deepgrey{
  border-color: $dark-grey;
}

.bg-grey-2 {
  background-color: lighten($dark-grey, 10%);
}

.bg-eggplant {
  background-color: rgb(52, 47, 64);
}

.green {
  color: $green;
}

.bg-green {
  background-color: $green;
}

.hover-bg-green {
  &:hover {
    background: $green;
  }
}

.blue {
  color: $blue;
}

.bg-blue {
  background-color: $blue;
}

.cursor-pointer {
  cursor: pointer;
}

.ParticipantApp {
  overflow-y: hidden;
  &__content {
    margin-top: 59px;
    margin-bottom: 110px;
  }
}

// hack for datepicker
.bootstrap-datetimepicker-widget .picker-switch {
  color: white;
}

.btn-question--active {
  background-color: $green;
}

// targeting datepicker
.input-group.date {
  input {
    font-family: 'Open Sans', sans-serif;
  }
}

// copy pasted from
// https://github.com/reactjs/react-modal/issues/125
.ReactModalPortal > * {
    // When the modal is closed, overlay div has no css class
    // This selector should be overridden by the `&--after-open` class below
    opacity: 0;
}
.ReactModal__Overlay {
    transition: opacity 200ms ease-in-out;
    background: rgba(0, 0, 0, 0.15);
    &--after-open {
        opacity: 1;
    }
    &--before-close {
        opacity: 0;
    }
}

.ReactModal__Body--open{
  overflow: hidden;
  position: fixed;
  width: 100%;
}

// React select
// .Select-value-label {
//   color: white !important;
// }
// .rebass-override input {
//   background-color: #F4F4F4 !important;
//   color: black !important;
//   border-width: 0 !important;
//   border-radius: 0 !important;
//   height: 34px !important;
// }

.rebass-override textarea {
  background-color: #F4F4F4 !important;
  color: black !important;
  border-width: 0 !important;
  border-radius: 0 !important;
  height: 68px !important;
}
.self-profile input  {
  background-color: #F4F4F4 !important;
  color: black !important;
  border-width: 0 !important;
  border-radius: 0 !important;
  // height: 34px !important;
}

input[type="file"] {
  background-color: transparent !important;
  color: #F4F4F4 !important;
  box-shadow: none;
}

// .rebass-input {
//   background-color: #F4F4F4 !important;
//   color: black !important;
//   border-width: 0 !important;
//   border-radius: 0 !important;
//   height: 34px !important;
// }

.rebass-select {
  option {
    background-color: black;
    color: #F4F4F4;
  }
}
