@import "sizes";

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.centered-horizontally {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
}


.flip-horizontally{
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.force-middle-screen{
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.bottom-scroll-fix{
  margin-bottom: 65px;
}

//spacing

.margin-side-15{
  margin-left: 15px;
  margin-right: 15px;
}

@media (max-width: 768px){
  .margin-side-15-small{
    margin-left: 15px;
    margin-right: 15px;
  }
}

.rem-right-margin-space{
  margin-right: 1rem;
}

.rem-bottom-margin-space{
  margin-bottom: 1rem;
}

.margin-bottom-1half{
  margin-bottom: 1.5rem;
}

.margin-bottom-4threm{
  margin-bottom: .25rem;
}

.margin-left-auto{
  margin-left: auto;
}

.margin-right-3rem{
  margin-right: 3rem !important;
}

.margin-right-halfrem{
  margin-right: .5rem;
}

.margin-right-auto{
  margin-right: auto;
}

.margin-right-1rem{
  margin-right: 1rem;
}

.margin-right-2rem{
  margin-right: 2rem;
}

.margin-left-1rem{
  margin-left: 1rem;
}

.margin-bottom-0{
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .side-margin-below-768{
    margin-left:15px;
    margin-right: 15px;
  }
}

.padding3-4s-rem{
  padding:.75rem
}

// width
.width-75percent{
  width:75% !important;
}

.width-max-400px{
  max-width: 400px;
}

// colors

.color-white{
  color: white !important;
}

.color-red{
  color:red !important;
}

.color-main-highlight{
  color: $mainHighlight;
}

.brand-green{
  color: $mainHighlight;
}

.b--brand-green{
  border-color: $mainHighlight;
}

.bg-brand-green{
  background: $mainHighlight;
}

.hover-green:hover{
  color: $mainHighlight;
}

.focus-green:focus{
  color: $mainHighlight !important;
}

.bg-gold{
  background: #dbb113;
}

.bg-red-urgent{
  background: #EA2626;
}

.bg-green-good{
  background: #0ebf40;
}

.bg-darkgrey{
  background-color: $dark;
}

.brand-orange{
  color:$brandOrange;
}

.b--brand-orange{
  border-color:$brandOrange;
}

.alt-orange{
  color: $orange;
}

.color-secondary-highlight{
  color:$secondaryHighlight;
}

.dark-teal{
  color: $darkTeal;
}

.bg-dark-teal{
  background: $darkTeal;
}

.b--dark-teal {
  border-color: $darkTeal;
}

.color-stay-white{
  color: white;
  &:hover{
    color: white;
  }
  &:active{
    color: white;
  }
  &:focus {
    color: white;
  }
  &:visited {
    color: white;
  }
}


//typography

.open-sans{
  font-family: 'Open Sans', sans-serif;
}

.text-center{
  text-align: center;
}

.text-uppercase{
  text-transform: uppercase;
}

.f18{
  font-size: 1.8rem;
}

.f16{
  font-size: 16px;
}

//misc

.text-shadow{
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.drop-shadow{
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.drop-shadow-light{
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
}

.unstyle-list{
  padding: 0;
}

.unstyle-list li{
  padding: 0;
  margin: 0;
  list-style: none;
}

[contenteditable=true]:empty:before{
  content: attr(placeholder);
  display: block; /* For Firefox */
}

.span-breaks{
  display: block;
  float: left;
  margin-right: 0.4em;
}

.visible-510px{
  display: block !important;
}

@media (min-width: 510px) {
  .visible-510px{
    display: none !important;
  }
}

.show-when-small{
  display: block !important;
}

@media (min-width: 40em) {
  .show-when-small{
    display: none !important;
  }
}

.hide-when-small{
  display: none !important;
}

@media (min-width: 40em) {
  .hide-when-small{
    display: block !important;
  }
}

.remove-letter-spacing{
  letter-spacing: 0;
}

.negative-mt2 {
  margin-top: -1rem;
}

.screen-reader-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.chart-container {
    width: 1000px;
    height:600px
}

.width-200px {
  width: 200px;
}

.internet-error {
  bottom: 0;
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding: 1rem;
}

.internet-error p {
  margin: 0;
}

.no-hyphens {
  hyphens: none;
}

.links-styled a {
  color: $blue !important;
  text-decoration: underline;
}

.link-styled {
  color: $blue !important;
  text-decoration: underline;
}

.disabled-cursor {
  cursor: not-allowed !important;
}