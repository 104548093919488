.referral-box{
  background: $dark;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.referrals-title{
  margin-bottom: 1.5rem;
  text-align: center;
}

.referral-participant-name{

}

.referral-item{
  border-bottom: 1px solid grey;
  padding: 1rem;
}

.referral-item:first-child{
  border-top: 1px solid grey;
}

.case-assign-heading{
  font-size: 1rem;
}

.case-assign-heading span{
  font-weight: 600;
}

.profile-answer-section{
  margin-bottom: 1.5rem;
}

@media (min-width: 550px){
  .profile-answer-section{
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
  }
}

.fix-assign-btn{
  font-size: 14px !important;
  border-radius: 4px !important;
}
