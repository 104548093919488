$darkVideoBg: #1b1c20;

.video-preview-page { 
    margin: 0 auto; 
    padding: 16px;
}    

.video-chat-wrapper > video {
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);/* Safari and Chrome */
    -moz-transform: translate(-50%, -50%); /* Firefox */
  }
  
.video-full-screen{
    position: absolute; 
    min-width: 100%;
    min-height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%),
  }

.video-preview-container {
    position: relative;
    width: 100%;
    height: 200px;
    margin: 0 auto;
    overflow: hidden;
    object-fit: cover;
}

.video-preview {
    position: absolute; 
    min-width: 100%; 
    height: 280px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotateY(180deg) ;
    -webkit-transform: translate(-50%, -50%) rotateY(180deg) ; /* Safari and Chrome */
    -moz-transform: translate(-50%, -50%) rotateY(180deg) ; /* Firefox */
}

.video-preview-info { 
    width: 300px;
    margin: 0 auto;
    padding-top: 2rem;
}


@media (min-width: 480px) {
    .video-preview {
        height: 320px;
    }

    .video-preview-container {
        height: 320px;
    }
}


@media (min-width: 768px) {

    .video-preview-container {
        height: 360px;
        margin: inherit;
    }

}

@media (min-width: 1024px) {
    .video-preview-info { 
        padding-top: 4rem;
    }
    
    .video-preview-page { 
        padding-top: 64px 
    }   
        
    .video-preview-container {
        width: 640px;
        height: 480px;
    }

    .video-preview {
        height: 480px;
    }
}

.roster-container  aside {
    border: none;
    width: 296px;
}

.roster-container {
    border-right: 0.0625rem solid #000000;
    background-color: $darkVideoBg;
    height: 100%;
    padding-top: 12px;
}

.quick-ease-out {
    transition: all .75s ease-out;
}

/////////////////////////////////////////
//// CONTROL BAR

.circle-btn-small {
    all: unset;
    width: 3rem;
    height: 3rem;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: $mainHighlight;
    font-size: 20px;

    &:hover {
        background-color: $dark;
        transition: all .1s ease-in;
        box-shadow: 0 .1rem 2rem rgba(0,0,0, .3);
        transform: translateY(-.1rem);
        border: 2px solid $mainHighlight;
    }

    &:active {
        transform: translateY(-.05rem);
    }
}

.control-bar {

    display: flex;
    align-items: center;
    border-radius: 0;
    background-color: $darkVideoBg;
    opacity: 1;
    border: none;
    box-shadow: none;
    flex-direction: row;
    width: 100%;
    bottom: 0;
    height: 5rem;
    padding: 0 1rem;
    justify-content: center;

    &__users-box {
        width: 3rem;
        height: 3rem;
    }

    &__users-button {
        @extend .circle-btn-small;

        &--active {
            border: 2px solid $mainHighlight;
            background-color: $dark;
        }
    }

    &__primary-buttons-box {
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        width: 100%;
    }

    &__mute-box {
    }

    &__mute-button {
        @extend .circle-btn-small;
        display:flex;
        flex-direction: column;
        position: relative;

        &--active {
            border: 2px solid $warning;
            color: lighten($warning, 25%);
            background-color: $dark;

            &:hover {
                color: white;
            }
        }

        &:disabled {
            color: $danger;
            cursor: default;
            background-color: transparent;
            border: none;
            transform: translateY(0);
            box-shadow: none;
        }

        span {
            font-size: 12px;
            position: absolute;
            width: 110px;
            bottom: -3px;
            color: $danger;
            text-align: center;
        }

    }

    &__leave-box {

    }

    &__leave-button {
        @extend .circle-btn-small;
        font-size: 23px;
        border-color: $danger;

        &:hover {
            border: 2px solid $danger;
        }

        &:active {
            background: $danger;
            transition: background-color .05s ease-in;
        }
    }

    &__secondary-nav-box {
        width: 3rem;
        height: 3rem;
    }

    &__secondary-nav-button-box {
        position: relative;

    }

    &__secondary-nav-button {
        @extend .circle-btn-small;
        font-size: 33px;
    }

    &__secondary-nav-button--active {
        background-color: $dark;
        transition: all .1s ease-in;
        box-shadow: 0 .1rem 2rem rgba(0,0,0, .3);
        transform: translateY(-.1rem);
        border: 2px solid $mainHighlight;
    }

    &__secondary-nav-menu-box {
        position: absolute;
        background: $darkVideoBg;
        border-radius: 5px;
        width: 300px;
        // height: 200px; 
        right: 0;
        // top: -217px;
        // top: -94px;
        box-shadow: 0 1rem 1.25rem rgba(0, 0, 0, .3);
    }

    &__secondary-nav-menu {
        display: flex;
        flex-direction: column;
    }

}


.label-mute-all {
    font-size: .9rem;
    margin-bottom: 0;
    color: $lighter;
    margin-left: .5rem;
}

.force-mute-toggle-box {
    display:flex;
    align-items: center;
    justify-content: center;

    .react-toggle--checked .react-toggle-track {
        background-color: $danger;
    }

    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: darken($danger, 10%);
    }

    .react-toggle-track-check,
    .react-toggle-track-x {
        display: flex;
        justify-content: center;
        align-items:center;
    }
}

.rotate-phone-down {
    transform: rotateZ(-224deg);
}

// 560 

@media (min-width: 560px) {

    .control-bar__primary-buttons-box {
        width: auto;
        justify-content: space-between;
    }
    
    .control-bar__mute-box {
        margin-right: 8rem;
    }
  
}

.roster-menu {
    box-shadow: -0.55rem -0.5rem 1.5rem rgba(0, 0, 0, .3);
}

