.section-header{
  color: $mainHighlight;
  font-size: 2.25rem;
  text-align: center;
  padding-bottom: 1rem;
}

.plan-type-header{
  color:white;
  font-size: 1.4rem;
  // margin-bottom: 1.5rem;
}

.plan-ul{
  color:white;
  list-style: none;
  font-size: 1.4rem;
}

.plan-item{
  border: none;
  color: white;
  text-align: left;
  cursor: pointer;
  &:hover{
    color:white;
  }
  &:focus{
    color:white;
  }
}

.plan-item:nth-child(even){
  background-color: #323232;
}

.plan-item:nth-child(odd){
  background-color: #444444;
}

.plan-item:hover{
  background-color: #555555;
}

.plan-box{
  background: $dark;
  border-radius: 4px;
  padding: 10px;
  margin-left: 25px;
  position: relative;
  // max-width: 70%;
}

.plan-checkbox{
  margin-right: 1rem !important;
}

.plan-copy{
  padding-left: 0;
  text-transform: uppercase;
}

.plan-options-button{
  font-size: 3rem;
  color: $mainHighlight;
  vertical-align:middle;
  a{
    color: $mainHighlight;
  }
}

.plan-indent{
  margin-left: 1.5rem;
}

.plan-indent .plan-options-button{
  padding-left: 10px;
}

.plan-due-time{
  color: $mainHighlight;
  font-size: 1rem;
  text-transform: uppercase;
}

.plan-need{
  position: absolute;
  right: 5px;
  top: 15px;
}


@media (min-width: 375px){
  .plan-need{
    top: 20px;
    right: 15px;
  }
}

.plan-late{
  border-color: red;
}

.task-detail-container{
  z-index: 1;
  position:fixed;
  background: yellow;
  left:0;
  top:0;
  width:100%;
  overflow: auto;
  height: 100%;
}

.actions-btn{
  border: none;
  background: $mainHighlight;
  text-shadow: none;
  color: white;
  font-weight: 600;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  &:hover{
    background: darken($mainHighlight, 10);
    color: white;
  }
  &:active{
    background: darken($mainHighlight, 10) !important;
    color: white !important;
  }
  &:focus{
    background: darken($mainHighlight, 10);
    color: white;
  }
}

.open > .actions-btn{
  border: none;
  background: $mainHighlight !important;
  text-shadow: none !important;
  color: white !important;
  font-weight: 600;
  &:hover{
    background: darken($mainHighlight, 10);
    color: white;
  }
  &:active{
    background: darken($mainHighlight, 10) !important;
    color: white !important;
  }
  &:focus{
    background: darken($mainHighlight, 10);
    color: white;
  }
}

.GoalsListItem{
  border-radius: 4px;
}

.goal-expand{
  color:$mainHighlight;
  &:hover{
    color: darken($mainHighlight, 10);
  }
}

.goal-title{
  color: $secondaryHighlight;
}

.add-item-bar {
  background-color: rgba($darker, 0.9);
  width: 100%;
  left: 0;
  bottom: 0;
  position: fixed;
  -webkit-box-shadow: 0px 0px 5px 1px $darker;
  -moz-box-shadow: 0px 0px 5px 1px $darker;
  box-shadow: 0px 0px 5px 1px $darker;
  z-index: 2;
  @media (min-width: 992px) {
    padding-left: 16.6%;
  }
}
