.case-note-extended {
    display: block;
    overflow: hidden;
    position: relative;
    background: $dark;
    padding-bottom: 20px;
}
  
.case-note-extended:after {
    position: absolute;
    bottom: 0;  
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(to top,
        $dark 7%, 
        rgba(255,255,255, 0) 92%
    );
    pointer-events: none; /* so the text is still selectable */
}

.negative-mt-10 {
    margin-top: -10px;
}