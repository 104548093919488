@import "./plan/plan";

body.noScroll {
  overflow: hidden !important;
  position:fixed;
  width: 100%;
}

hr{
  border-color: #555555;
}

// input[type=checkbox] {
//   transform: scale(1.5);
// }

iframe{
  border: none;
}

.hyphen {
   /* overflow-wrap: break-word;
   word-wrap: break-word;

   -ms-word-break: break-all;
    This is the dangerous one in WebKit, as it breaks things wherever
   word-break: break-all;
    Instead use this non-standard one:
   word-break: break-word; */

  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  hyphens: auto;
}

/* MAIN CONTENT */
.main-content {
  $width: calc(100% - 260px);
  margin-top: 100px;
  width: $width;
  &-baropen {
    width: $width;
    transition: width .5s ease;
  }
  &-barclosed {
    width: 100%;
    transition: width .5s ease;
  }
}

/* SIDEBAR */

.sidebar {
  position: fixed;
  right:-260px;
  top:0;
  height: 100%;
  width: 260px;
  overflow: auto;
  z-index: 53;
  transition: right .5s ease;
  a {
    &:hover {
      color :#90c42f;
    }
  }
  &-active-link {
    color: #90c42f;
  }
}

.darker-gray {
  color: #232323;
}

.bg-darker-gray {
  background-color: #232323;
}

.bg-grey-purple {
  background-color: #342f40;
}

.h-bg-dark-purple:hover{
  background-color: darken(#5e2ca5, 20);
}

.hover-brand-green:hover,
.hover-brand-green:focus {
  color: #90c42f;
}

.sidebar-shadow{
  position: relative;
  width: 100%;
  height:100%;
  background-color: rgb(0,0,0);
  opacity: 0;
  z-index: 52;
  top:0;
  left:0;
  transition: opacity 2s ease;
}

.sidebar.open {
  right:0;
}

.sidebar-shadow.open{
  position: fixed;
  animation: fade-in .5s ease;
  animation-fill-mode: forwards;
}

.sidebar-shadow.closed{
  animation: fade-out .5s ease;
  animation-fill-mode: forwards;
}

.sidebar a:hover{
  color:#90c42f !important;
}

.sidebar-active-link{
  color: #90c42f !important;
}

@keyframes fade-in {
    0%   {opacity: 0;}
    100%  {opacity: .4;}
}

@keyframes fade-out {
    0%   {opacity: .4; position: fixed;}
    99%  {opacity: 0; position: fixed;}
    100% {position: relative;}
}

/* TOP NAVIGATION */
.fixed-header {
  height: 50px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 51;
}

.second-nav {
  top: 50px;
  height: 50px;
  position: fixed;
  width: 100%;
  background: #232323;
  z-index: 52;

  &-item {
    padding-top: 15px;
    padding-bottom: 7px;
    border-bottom: 8px solid #232323;
    &:hover, &-active {
      border-color:#90c42f;
    }
    &:active {
      border-color: #90c42f;
    }
    &-backstopping {
      margin-right: 4rem;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

/* MEDIA QUERY */
@media (min-width: 949px) {
  .MediaQuery-second-nav-hidden{
    display: none;
  }
}

@media only screen and (min-width: 1025px) {
    .MediaQuery-mobile{
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .MediaQuery-desktop{
        display: none;
    }
}

@media only screen and (min-width: 640px) {
    .MediaQuery-small-mobile{
        display: none;
    }
}

@media only screen and (max-width: 639px) {
    .MediaQuery-small-desktop{
        display: none !important;
    }
}

@media only screen and (min-width: 512px) {
    .MediaQuery-appt-single-col{
        display: none;
    }
}

.red-count{
  font-family: 'Roboto', sans-serif;
  position: absolute;
  height: 20px;
  top: -5px;
  right:-15px;
  color:white;
  font-size: 12px;
  background:#EA2626;
  border-radius: 12px;
  color: white;
  font-weight: bold;
  height: 17px;
  padding: 3px 3px 0 3px;
  text-align: center;
  min-width: 16px;
}


.contacts-sidebar {
  height: calc(100% - 100px);
  overflow-y: auto;
  // overflow: hidden;
  position: fixed;
  right: 0;
  top: 100px;
  width: 260px;
  z-index: 50;

  &.open {
    animation: slide-in 0.5s ease;
    animation-fill-mode: forwards;
  }

  &-shadow.open {
    position: fixed;
    animation: fade-in .5s ease;
    animation-fill-mode: forwards;
  }

  &.closed {
    animation: slide-out 0.5s ease;
    animation-fill-mode: forwards;
  }

  &-shadow.closed {
    animation: fade-out .5s ease;
    animation-fill-mode: forwards;
  }

  @media (max-width: 948px) {
    height: calc(100% - 50px);
    position: fixed;
    // right: -260px;
    top:50px;

    &-shadow {
      position: relative;
      width: 100%;
      height:100%;
      background-color: rgb(0,0,0);
      opacity: 0;
      z-index: 1;
      top:0;
      left:0;
      transition: opacity 2s ease;
    }
  }
}

@media (max-width: 948px) {
  .content{
    margin-top: 50px;
  }

  .second-nav {
    display: none !important;
  }

  .main-content,.main-content-baropen {
    width: 100%;
  }

  .main-content {
    margin-top: 50px;
  }

}

/* HAMBURGER */
.contacts-menu-btn{font-size: 1.7rem;}

.bar{
  width: 30px;
  height: 4px;
  margin: 6px 0;
}

.mr3-5{
  margin-right: 1.5rem;
}

/* CONTACTS SIDEBAR */
.contact-item {
  border-bottom: 1px solid #323232;
  &-active > div{
    background: #323232;
  }
  &:hover {
    background-color: #323232;
  }
}

.contact-side-image {
  width: 55px;
  height: 55px;
  border-radius: 100%;
  margin-right: 12px;
  margin-left: 8px;
  object-fit: cover;
}

.small-profile-image {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  object-fit: cover;
}

.medium-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.green-border {
  border: 4px solid #90c42f;
}

.gray-border {
  border: 4px solid #323232;
}

.contact-stat {
  padding: 0 2px;
  min-width: 24px;
  height: 24px;
  text-align: center;
  vertical-align: middle;
  line-height: 24px;
  border-radius: 2px;
  color: white;
}

.urgent{
  background-color: #EA2626;
}

.upcoming{
  background-color: #dbb113;
}

.good{
  background-color: #0ebf40;
}

.participant-link {
  transition: all .25s ease;
  &:hover {
    padding-left: 15px;
  }
  &-active {
    padding-left: 15px;
    &:before {
      content: "";
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid #90c42f;
      position: absolute;
      left: 0px;
      top: 2px;
    }
  }
}

@keyframes slide-in {
 from {right: -260px;}
 to {right: 0;}
}

@keyframes slide-out {
 from {right: 0;}
 to {right: -260px;}
}

.dark-text-input{
  background-color: black;
  color: white;
  border-radius: 2px;
  border: none;
  padding: 10px;
}

.search-bar {
  position: relative;
  &-input {
    padding-left: 32px;
    width: 100%;
  }
  i {
    position: absolute;
    left:10px;
    color: #898989;
  }
}

/* PARTICIPANT CONTENT */
.participant-link {
  transition: all .25s ease;
  &:hover {
    padding-left: 15px;
  }
  &-active {
    padding-left: 15px;
    &:before {
      content:"";
      width:0;
      height:0;
      border-top:7px solid transparent;
      border-bottom:7px solid transparent;
      border-left:7px solid #90c42f;
      position: absolute;
      left: 0px;
      top: 2px;
    }
  }
}

.participant-links{
  width: 290px;
}

.side-menu{
  width: 100%;
}

.participant-content {
  width:100%;
}
.main-area-content{
  width:100%;
}

.left-triangle{
  position: absolute;
  left: -15px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right:15px solid #131338;
}

.right-triangle{
  position: absolute;
  right: -15px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left:15px solid #131338;
}

.square{
  width: 40px;
  height: 40px;
  background-color: yellow;
}

.messages-box{
  height: 140px;
  overflow: scroll;
  background-color: rgb(52, 47, 64);
}

@media (min-width: 321px){
  .messages-box{
    height: 230px;
    overflow: scroll;
    background-color: rgb(52, 47, 64);
  }
}


.message-text-input{
  background-color: #131338;
  color: white;
  border:none;
}

.fake-check{
  color: #232323;
}

.fake-check-active{
  color: #90c42f;
}

textarea[name=message] {
    resize: vertical;
}

@media (min-width: 640px){
  .participant-content {
    padding-left: 0;
    width: calc(100% - 230px);
  }

  .main-area-content{
    padding-left: 0;
    width: calc(100% - 230px);
  }

  .participant-links, .side-menu{
    width: 230px;
  }
  .participant-links, .side-menu{
    padding-right: 16px;
  }
}

// wrong password shake

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* Animation */

@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}



// treatment plan

.tasks-header{
  background: #131437;
}

.bb-purple{
  border-bottom: 1px solid #48154A;
}

.bt-purple{
  border-top: 1px solid #48154A;
}

.repeating-parent{
  background: #131437;
  border-top: 1px solid #48154A;
  border-bottom: 1px solid #48154A;
}

.task-list-item{
  background: #131437;
  border-bottom: 1px solid #48154A;
  &:hover{
    background: #1D0B28;
    cursor: pointer;
  }
}

.task-list-item.nohover{
  background: #131437;
  border-bottom: 1px solid #48154A;
  &:hover{
    background: #131437;
    cursor: default;
  }
}

.task-wrapper:first-of-type .task-list-item{
  border-top: none;
}

.task-wrapper:last-of-type .task-list-item{
  border-bottom: none;
}

// Hack for fixing styling bug
.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover{
  background: darken($green, 20);
  cursor: pointer;
}

.week-button{
  padding: 15px;
}

.week-button-active{
  padding: 15px;
  background-color: #90c42f;
}

.default-cursor{
  cursor: default !important;
}

.text-small{
  font-size: .75rem;
}

.tree-link-parent{
  padding:10px;
}

.tree-link:hover{
  color:#90c42f;
}

.fac-service-link:hover{
  cursor: pointer;
  &::after{
    content: ' manage personnel';
    color: #90c42f;
  }
}

.fac-service-link-view:hover{
  cursor: pointer;
  &::after{
    content: ' view personnel';
    color: #90c42f;
  }
}

.SetMyLocation__form .picker-container .picker-column .picker-item.picker-item-selected {
  color: #90c42f;
}

.grey-lighter-on-hover:hover {
  background-color: #555555;
}

.circle-step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 1rem;
  color: #fff;
  line-height: 30px;
  text-align: center;
}
