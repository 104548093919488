a{
  // color:white;
  // text-decoration: none;
  transition: all 0.2s ease;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

.participantCardListContainer{
  a{
    color:white;
    text-decoration: none;
  }
  a:visited {
    color: white;
    text-decoration: none;
  }
  a:hover {
    color: $mainHighlight;
    text-decoration: none;
  }
  a:focus {
    text-decoration: none;
    color: $mainHighlight;
  }
  a:active {
    text-decoration: none;
    color: $mainHighlight;
  }
}

// Provider directory

.panel-title{
  text-decoration: none;
}

.panel-title a{
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
    text-decoration:underline;
  }
  &:focus {
    color: white;
    text-decoration:underline;
  }
  &:active {
    color: white;
    text-decoration:underline;
  }
}

.searchresultitem{
  &:hover {
    background: darken($dark,5);
  }
  &:focus {
    background: darken($dark,5);
  }
  &:active {
    background: darken($dark,5);
  }
}

// search services

.infowindow a{
  color: $dark;
  text-decoration: underline;
  &:hover{
    color: $mainHighlight;
  }
}

a.white{
  color:white;
  &:hover{
    color: darken(white, 10);
    opacity: 1;
  }
  &:active{
    color: darken(white, 10);
  }
  &:focus{
    color: darken(white, 10);
  }
}
