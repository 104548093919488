input.form-control.custom-slider{
  background:$dark;
  border: none;
}

input[type=range] {
    /*removes default webkit styles*/
    -webkit-appearance: none;

    /*fix for FF unable to apply focus style bug */
    border: 1px solid white;

    /*required for proper track sizing in FF*/
    width: 300px;
}
input[type=range]::-webkit-slider-runnable-track {
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $mainHighlight;
    margin-top: -4px;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
}

input[type=range]::-moz-range-track {
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}
input[type=range]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $mainHighlight;
}

/*hide the outline behind the border*/
input[type=range]:-moz-focusring{
    outline: none;
    outline-offset: -1px;
}

input[type=range]::-ms-track {
    height: 5px;

    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;

    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;

    /*remove default tick marks*/
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
}
input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
}
input[type=range]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $mainHighlight;
}
input[type=range]:focus::-ms-fill-lower {
    background: #888;
}
input[type=range]:focus::-ms-fill-upper {
    background: #ccc;
}

.input-group.date .input-group-addon span {
  color:white;
}

// removes red line for firefox
:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow:none;
}

.custom{
  display: block;
  width: 100%;
  padding: 7px 10px;
  color: #333;
  margin-bottom: 10px;
  border-radius: 3px;
  border: none;
}

.dark-text-area{
  display: block;
  width: 100%;
  padding: 7px 10px;
  color: #333;
  margin-bottom: 10px;
  border-radius: 3px;
  border: none;
  background: #292929;
  border: 1px solid #5a5a5a !important;
  color:white;
}

.cog-dd{
  position: relative;
}

.dd{
  z-index: 3;
  position: absolute;
  background: rgb(23, 125, 135);
  border: 4px solid black;
  // box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.dd-plan-item-complete{
  left: -111px;
  top: -41px;
}

.dd-plan-item {
  left: -169px;
  top: -41px;
}

.dd-share-plan{
  left: -124px;
  top: -41px;
}

.dd button{
  padding: 10px 16px;
  border: none;
  background: rgb(23, 125, 135);
  border-bottom: 1px solid #1a6167;
  &:hover{
    background: rgb(11, 75, 82);;
    cursor: pointer;
  }
  &:last-of-type{
    border-bottom: none;
  }
}

.invisible-wall{
  width: 100%;
  height:100%;
  z-index: 2;
  position: fixed;
  top:0;
  left: 0;
}

.dd-arrow-right {
  width: 0;
  height: 0;
  left: 4px;
  top: -41px;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  position: absolute;
  border-left: 15px solid #000000;
}
