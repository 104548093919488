.b--green {
  border-color: $greenAlt;
}

.green {
  color: #90c42f;
}

.bg-green{
  background-color: $greenAlt;
}

.bg-dark-gray {
  background-color: #323232;
}
