.acivilate-input{
  background: $dark;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
}

.acivilate-modal-event-title{
  width: 100%;
  font-size:1.75rem;
  margin-bottom: 1rem;
  color: $mainHighlight;
}

.category-icon-side{
  margin-right: 2.2rem;
  margin-bottom: 1.5rem;
  color: $mainHighlight;
}

.input-calendar{
  width: 229px;
}

.acivilate-dropdown-time{
  border:0;
  font: .75rem "Oswald", Sans-Serif;
  background: $dark;
  color: $lighter;
}

.notes-number {
    display:inline-block;
    line-height:0px;

    border-radius:50%;
    background: $dark;
    font-size:1rem;
    margin-left: .25rem;
}

.notes-number span {
    display:inline-block;

    padding-top:50%;
    padding-bottom:50%;

    margin-left:8px;
    margin-right:8px;
}

.event-note{
  padding: 10px;
  padding-top: 20px;
  background: $dark;
  margin-bottom: .75rem;
  position: relative;
}

.event-note:last-of-type{
  margin-bottom: 0;
}

.event-note-delete{
  color: $lighter;
  float:right;
  font-size: .75rem;
  position: absolute;
  top: 8px;
  right: 8px;
}

.modalActionButtonContainer{
  text-align:center;
}
