@mixin button-reset {
  margin : 0;
  padding : 0;
  border : 0;
  background : transparent;
  cursor : pointer;
  &:focus {
    outline: none;
  }
}

.SelectNeedCategories {
  &__instructions {
    color: white;
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem 0;
  }
  &__icon {
    @include button-reset;
    // width: 6rem;
    // height: 6rem;
    // min-width: 20vw;
    // padding: 3rem;
    margin: .75rem;
    color: white;
    background-color: $dark-grey;
    border: solid 3px $dark-grey;
    border-radius: 5px;
    @media (min-width:769px) {
      &:hover {
        border: solid 3px $green;
        //color: $green;
      }
    }
    &--selected {
      background: $green;
      border: solid 3px $green;
      // border-radius: 5px;
    }

    &--spacer {
      background-color: transparent;
      cursor: default;
      &:hover {
        border: none;
      }
    }

    i {
      font-size: 3rem;
      margin-bottom: .5rem;
    }
  }
  @media (max-width:415px) {
    &__icon {
      // height: 5rem;
      i{
        font-size: 2rem;
      }
    }
    &__instructions {
      color: white;
      font-size: 1rem;
      text-align: center;
      margin: .75rem 0;
    }
  }
  &__icon-mobile {
    @include button-reset;
    // min-width: 20vw;
    // padding: 3rem;
    margin: .5rem auto;
    color: white;
    background-color: $dark-grey;
    border-radius: 5px;
    padding: .5rem;
    padding-left: 1rem;
    @media (min-width:769px) {
      &:hover {
        border: solid 3px $green;
        //color: $green;
      }
    }
    &--selected {
      background: $green;
      border: solid 3px $green;
      // border-radius: 5px;
    }

    &--spacer {
      background-color: transparent;
      cursor: default;
      &:hover {
        border: none;
      }
    }

    i {
      font-size: 3rem;
      margin-right: .5rem;
    }
  }
}

.border-none {
  border:  none !important;
}

.PreScreenQuestion {
  &__description {
    color:white;
    margin-bottom: 1rem;
  }
}

.button-question {
  @include button-reset;
  border: 1px solid rgba(255,255,255,.5);
  border-radius: 5px;
  color: white;
  margin-right: 2rem;
  padding: 0.5em 2em;
  &--active, &:hover {
    border-color: $mainHighlight;
    background-color: $mainHighlight;
  }
}

.FacilitiesMap {
  height: 80vh;
}
