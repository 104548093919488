
// ::-webkit-scrollbar {
//     width: 12px;
// }
//
//
// /* Track */
//
// ::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//     -webkit-border-radius: 10px;
//     background:rgba(80,80,80, .25);
//     border-radius: 10px;
// }
//
//
// /* Handle */
//
// ::-webkit-scrollbar-thumb {
//     -webkit-border-radius: 10px;
//     border-radius: 10px;
//     background: rgba(0, 0, 0, 0.4);
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
// }
//
// ::-webkit-scrollbar-thumb:window-inactive {
//     background: rgba(0, 0, 0, 0.4);
// }

.Select ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
.Select ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
}
