// @import url(https://fonts.googleapis.com/css?family=Oswald:300,400);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);
@import url(https://fonts.googleapis.com/css?family=Roboto:400);

html, body {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  background: $darker;
  color: white;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
}

a {
  color: inherit !important;
  text-decoration: none;
}

label{
  font-weight: normal;
}

.remove-pointer {
  cursor: default !important;
}

.hack-hover-bg-green:hover {
    background: #323232 !important;
}

.tree-expander {
  color: white !important;
  background-color: #232323;
  border: 1px solid gray;
}

.tree-expander:hover {
    background: $blue !important;
    border: 1px solid gray;
}

.removePointer:hover {
  cursor: default !important;
}

@mixin button-reset {
  margin : 0;
  padding : 0;
  border : 0;
  background : transparent;
  cursor : pointer;
  &:focus {
    outline: inherit !important;
  }
}


@mixin accessible-button-reset {
  all: unset;
}

.button-reset {
  @include button-reset();
}

.accessible-button-reset {
  margin : 0;
  padding : 0;
  border : 0;
  background : transparent;
  cursor : pointer;
}

.modal-open {
  padding:0 !important; // prevents modal from pushing content over
}

button.searchresultitem{
  &:hover{
    i{
      color:white !important;
    }
  }
  &:active{
    i{
      color:white !important;
    }
  }
  &:focus{
    i{
      color:white !important;
    }
  }
}

.eula{
  font-size: .75rem;
  letter-spacing: 0;
}

.eula ol li{
  margin-bottom: 1rem;
}
.eula ul li {
  margin-bottom: 1rem;
}

.centered-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.wrench-bg-repeat{
  background-image: url(/assets/images/Wrench.png);
  background-repeat: repeat;
}

.subcontainer{
  //padding-top:20px;
}

.bg32{
  background-color: #323232;
}

.bg23{
  background-color: #232323;
}

.button {
  color: white;
}

.loginHelpContainer{
  width: 300px;
}

@media (min-width: 450px){
  .loginHelpContainer{
    width: 425px;
  }
}

/* Make the badge float in the top right corner of the button */
.button__badge {
  background-color: #fa3e3e;
  border-radius: 2px;
  color: white;
  padding: 1px 3px;
  font-size: 10px;
  position: absolute; /* Position the badge within the relatively positioned button */
  top: 0;
  right: 0;
}

.redbadge {
  background-color: #e30000;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
}

.orangebadge {
  background-color: $secondaryHighlight;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  margin-top:16px;
}

.greenbadge {
  background-color: $mainHighlight;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
}

.redSquareBadge{
  background-color: #fa3e3e;
  height:30px;
  width:50px;
  color:#fff;
  font-size:20px;
  font-weight:bold;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  margin-top:5px;
  margin-bottom:5px;

}

.whiteHoursBox{
  height:30px;
  width:50px;
  color:#fff;
  font-size:20px;
  font-weight:bold;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  margin-top:5px;
  margin-bottom:5px;
  border: 1px solid #232323;
}

.greenHoursBox{
  height:30px;
  width:50px;
  color:$mainHighlight;
  font-size:20px;
  font-weight:bold;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  margin-top:5px;
  margin-bottom:5px;
  border: 1px solid #232323;
}

.orangeHoursBox{
  height:30px;
  width:50px;
  color:$secondaryHighlight;
  font-size:20px;
  font-weight:bold;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  margin-top:5px;
  margin-bottom:5px;
  border: 1px solid #232323;
}

.greenSquareBadge{
  background-color: $mainHighlight;
  height:30px;
  width:50px;
  color:#fff;
  font-size:20px;
  font-weight:bold;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  margin-top:5px;
  margin-bottom:5px;

}

.redDateBadge{
  background-color: #fa3e3e;
  height:30px;
  width:100px;
  color:#fff;
  font-size:20px;
  font-weight:bold;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  margin-top:5px;
  margin-bottom:5px;

}

.greenDateBadge{
  background-color: $mainHighlight;
  height:30px;
  width:100px;
  color:#fff;
  font-size:20px;
  font-weight:bold;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  margin-top:5px;
  margin-bottom:5px;

}

.tabButton{
  color:#fff;
  background: #323232;
  border: 2px solid rgba(144,195,146,0.40);
  border-radius: 2px;
  border: 1px solid $mainHighlight;
  width:100%;
  height:75px;
  font-weight: normal;
  font-size:16px;
  text-align: left;
}

.tabButton > a {
  color: inherit;
  text-decoration: none;
}

.tabButtonActive{
  background: $mainHighlight;
  border-radius: 2px;
  width:100%;
  height:75px;
  font-weight: normal;
  font-size:16px;
  text-align: left;

}

@media (min-width: $screen-lg-min) {
  .tabButton{
    font-size:18px;
  }
  .tabButtonActive{
    font-size: 18px;
  }
}

.tabButtonActive > a {
  color: inherit;
  text-decoration: none;
}

.tabItem{
  margin-left:5%;
  float:left;
}

.tabContainer {
  padding-top:10px;
  padding-bottom:10px;

}
.messagesTab {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: $mainHighlight;
  color:#000;
  height:25px;
  line-height: 25px;
  text-align: center;
}

.topRightActionText{
  font-size: 12px;
}

.iconImg{
  height: 30px;
  width: 30px;
  margin-top:3px;
  margin-bottom:12px;
  margin-left:15px;
}

.centerContentContainer{
  background-color: #232323;
  height:auto;
}

.loadingCenterContentContainer{
  background-color: #232323;
  height:850px;
  display: table;
}

.centerContentTitle{
  color:$mainHighlight;
  text-align: center;
  font-size:20px;
  padding-top: 15px;
  margin-bottom: 15px;
}

.centerContentTitleWhite{
  color:#fff;
  text-align: center;
  font-size:24px;
  margin-top: 30px;
  margin-bottom:30px;


}

.centerContentTitleWhite2{
  color:#fff;
  text-align: center;
  font-size:20px;
  margin-top: 30px;
  margin-bottom:30px;
  text-decoration: underline;

}

.tableItem {
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
}

.centerContentWhite{
  color:#fff;
  text-align: center;
  font-size:16px;
  margin-top: 30px;
  margin-bottom:30px;
}

.centerContentWhiteLeftAligned{
  color:#fff;
  text-align: left;
  font-size:16px;
  margin-top: 30px;
  margin-bottom:30px;
}

.centerContentBigTitleWhite{
  color:#fff;
  text-align: center;
  font-size:72px;
  margin-top: 30px;
  margin-bottom:30px;
}

.generalsidebar{
  background-color: #232323;
  text-align: left;
  display:table;
  height:850px;
}

.verticallyCenteredMenu{
  display: table-cell;
  vertical-align: middle;
}

.verticallyCenteredContent{
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.menuButtonWithMargin{
  margin-bottom:20px;
}

.greenLine{
  opacity: 0.4;
  border: 2px solid $mainHighlight;
}

.greyLine{
  opacity: 0.25;
  border: 2px solid #979797;
}

.floatLeftSmallImage{
  float: left;
  margin: 0px 15px 15px 0px;
  width:25px;
}

.clearFloat{
  clear: both;
}

.messageText  {
  color:#fff;
  font-size:10px;
  margin-left:40px;
  font-family: 'Roboto', sans-serif;
}

.messagesContainer  {
  height: 300px;
  overflow: auto;
  overflow-x: hidden;
  padding-left: 40px;
  padding-right: 15px;
}

.notificationText {
  color:#fff;
  font-size:10px;
  margin-left:40px;
  font-family: 'Roboto', sans-serif;
}

.notificationsContainer {
  height: 300px;
  overflow: auto;
  padding-right: 40px;
  padding-left: 15px
}

.searchBar{
  background: #232323;
  border-right:1px solid #979797;
  border-top:0px;
  border-bottom:0px;
  border-left:0px;
  color:#fff;
  letter-spacing: 0px;
  width:75%;
  padding:5px;

}

.searchInputControl{
  background-color: #323232;
}

.searchInputControl::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    $mainHighlight;
}
.searchInputControl:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    $mainHighlight;
  opacity:  1;
}
.searchInputControl::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    $mainHighlight;
  opacity:  1;
}
.searchInputControl:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    $mainHighlight;
}


.participantListSearchContainer{
  background: #323232;
  text-align: center;
  width: 300px;
  margin-right:auto;
  margin-left:auto;
  margin-top:10px;

}

.searchContainer{
  background: #232323;
  padding-left:5px;
  text-align: left;

}

.searchImage{
  height:20px;
  margin-left:10px;
  cursor: pointer;
}

.rightBarSearchContainer{
  background: #232323;
  padding-left:5px;
  text-align: left;
  position: relative;
}

.rightBarSearchImage{
  position: absolute;
  height:20px;
  margin-left:10px;
  cursor: pointer;
  top: 6px;
  right:6px;
}


#rightHalf {
  margin-top: 5px;
  width: 0%;
  margin-left: 1em;
  position: relative;
  height: 100%;
}

.a-z{
  position:absolute;
}

.sortImage{
  background-color: #232323;
  margin-left:10px;
  cursor: pointer;
  height:30px;
  width:30px;
}

.sortImageContainer{
  height:30px;
  padding-left:10px;
  background-color: #232323;
}

.participantListContainer{
  margin-top:10px;
  height: auto;
  overflow: auto;
}

.participantListContainer a{
  &:hover{
    text-decoration: none;
    .participantName{
      color:$mainHighlight;
    }
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
  a:focus {
    text-decoration: none;
  }
  a:active {
    text-decoration: none;
  }
}

.tabIcon{
  margin-right: 5px;
  margin-left:5px;
  height:30px;

  vertical-align: middle;
  margin-top:25px;
  float:left;
}

.loginImage{
  width:30%;
}

.loginImageContainer{
  text-align: center;
  padding-top: 40px;
  width: 150px;
  margin: 0 auto;
  padding-left: 17px;
}

@media (min-width: 321px){
  .loginImageContainer{
    width:250px;
  }
}

.loginControl{
  margin: 0 auto;
  display: block;
  width: 240px;
  height:30px;
  padding: 0px 10px;
  line-height: 1.42857143;
  background:  #444444;
  border-radius: 2px;
  color:#fff;
  border-color:#323232;
  font-size:12px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.appActionBarIcon{
  margin-right: 5px;
  margin-left:5px;
  height:15px;
  vertical-align: middle;
}

.logoutActionButtonContainer{
  position: relative;
  height:15px;
  top:0px;
  text-align: right;
  cursor: pointer;
  margin-top: 1rem;
}

.appBarContainer{
  /*position: relative;*/
  height:15px;
  top:0px;
  text-align: right;
  cursor: pointer;
  color:$secondaryHighlight;
  font-size: 12px;
}

.loginLink{

  cursor: pointer;
  color:$secondaryHighlight;
  font-size: 12px;
}

.loginLink :hover{

  cursor: pointer;
  color:$secondaryHighlight;
  font-size: 12px;
}

.footerLink{

  cursor: pointer;
  color:$secondaryHighlight;
  font-size: 12px;
}

.footerLink :hover{

  cursor: pointer;
  color:$secondaryHighlight;
  font-size: 12px;
}

.loginActionButtonContainer{
  margin-top:15px;
  margin-bottom:5px;
  text-align: center;
}

.loginControl:focus {
  background-color: $mainHighlight;
}

.loginControl:focus::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #fff;
}
.loginControl:focus:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #fff;
  opacity:  1;
}
.loginControl:focus::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #fff;
  opacity:  1;
}
.loginControl:focus:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    #fff;
}

.tabText{
  margin-left:45px;
  padding-top:29px;
  color:#fff;
}

.tabTextNoImage{
  padding-top:24px;
  color:#fff;
  text-align: center;
}

.greyColorText{

  color:grey;

}

.tabTextMultiline{
  margin-left:45px;
  padding-top:12px;
  color:#fff;
}

.tabContainer{
  font-size: 10px;
  font-weight: normal;
  color: #fff;
  background: #323232;
  border-left: 1px solid $mainHighlight;
  border-bottom: 1px solid $mainHighlight;
  border-right: 1px solid $mainHighlight;
  border-top: 1px solid $mainHighlight;
  padding:10px;
}

.navContainer{

  font-size: 10px;
  font-weight: normal;
  color: #fff;
  background: #323232;
  border-left: 1px solid $mainHighlight;
  border-bottom: 1px solid $mainHighlight;
  border-right: 1px solid $mainHighlight;
  border-top: 1px solid $mainHighlight;
  padding:10px;
}

.mapContainer{
  height:500px;
  width: 100%;
}

.service-grid-icon-container{
  text-align:center;
  display: table;
}

.service-grid-icon{
  height:40px;
  vertical-align: middle;
  display: table-cell;
  background: gray;
}

.service-grid-icon-2-container{
  height:50px;
  width:50px;
  display: table;
  vertical-align: middle;
  display: table-cell;
  margin-right:70px;
}

.service-grid-icon-2{
  height:40px;
  vertical-align: middle;
  display: table-cell;
  margin-right:70px;
}

.panel-body {

  background-color: #232323;
  text-align: left;
  font-size: 12px;
}

.panel-default>.panel-heading {
  background-color: $mainHighlight !important;
  background-image: none !important;
  border-color: $mainHighlight !important;
}

.panel-title{
  color:#fff;
  background-color: $mainHighlight;
  text-decoration: underline;
}

// input[type=checkbox], input[type=radio]{
//   font-size: xx-large;
// }

.nav-tabs {
  border-bottom: 1px solid $mainHighlight;
}

ul.nav-tabs > li {
  background: #393939;
  border: 1px solid $mainHighlight;
  border-radius: 2px;
  margin-right:10px;
}

ul.nav-tabs > li.active {
  background-color:  $mainHighlight;
  border: 0px solid $mainHighlight;

}

ul.nav-tabs > li > a {
  color: #fff;
  border: 0px ;
}

ul.nav-tabs > li.active > a {
  color: #fff;
  background-color: $mainHighlight;
  border: 0px ;
}

ul.nav-tabs > li > a:hover{
  color:  $mainHighlight;
  background: #393939;
  border: 0px ;
}

ul.nav-tabs > li > a:focus{
  background-color:  $mainHighlight;
  color: #fff;
  text-decoration: none;
  outline: none;
  border: 0px ;

}

ul.nav-tabs > li.active > a:hover{
  color:  $mainHighlight;
  border: 0px ;
}

ul.nav-tabs > li.active > a:focus{
  background-color:  $mainHighlight;
  color: #fff;
  text-decoration: none;
  outline: none;
  border: 0px ;
}

.textInput{
  display: block;
  width: 75% !important;
  height: 30px;
  padding: 0px 10px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.textareaInput{
  display: block;
  width: 75%;
  padding: 0px 10px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.selectInput{
  display: block;
  width: 75%;
  height: 30px;
  padding: 0px 10px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  margin-bottom:10px;
}

.radiocheckboxContainer{
  margin-bottom:8px;
}

.radioLabel{
  font-family:"Roboto",sans-serif;
  font-size:12px;
  margin-right:5px;

}

.checkboxLabel{
  font-family:"Roboto",sans-serif;
  font-size:12px;

}

.checkboxInput{
  background: $mainHighlight;
  border-radius: 2px;
  color: $mainHighlight;
  font-size: x-large;

}

.inline-error{
  color: #fff;
  background-color: #9c2b2e;
  font-size: 12px;
  padding:5px;
}

.form-group + .fix-error-margin {
  margin-right: -15px;
  margin-left: -15px;
}

.errorText{
  font-size: 15px;
}

.inline-success{
  color:#3c763d
}

.formTitle{
  color:$mainHighlight;
  text-align: center;
  font-size:20px;
  margin-top: 10px;
}

.prescreenquestionText{
  color: gray;
  background: #232323;
  border:none;
  width:75%;
  font-size:14px;
}

.checkboxLabel{
  font-size:12px;
}

.formLabel{
  font-size:14px;
}

.requiredInstructionText{
  font-size:16px;
}

.redasterisk{
  color:#d00;
  font-size:18px;
}

.form-group.requiredField .control-label:after {
  color: #d00;
  content: "*";
  position: relative;
  margin-left: 6px;
  top:-1px;
  font-size:18px;
}

.profileForm{
  width:95%;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:5px;
}

.psdForm{
  width:55%;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:5px;
}

.profileFormRightContainer{
  height:640px;
  overflow-y: hidden;
  overflow-x: hidden;
  width:100%;
}

.profilePanelHeading{
  font-size:16px;
  border-radius: 2px;
  background-color:  $mainHighlight;
  color:#fff;
  text-align: center;
  padding:2px;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:5px;
  width:95%;
  cursor: pointer;
}

.alertPanelHeading{
  font-size:16px;
  border-radius: 2px;
  text-align: left;
  padding:2px;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:5px;
}

.formCategoryHeading{
  font-size:16px;
  border-radius: 2px;
  background-color:  $mainHighlight;
  color:#fff;
  text-align: center;
  padding:2px;
  cursor: pointer;
  margin-bottom: 5px;
}

.formActionButton{
  border-radius: 2px;
  height:36px;
  color:#fff;
  border: none;
  min-width: 123px;
  margin-right:5px;
}

.formActionButtonSmall{
  border-radius: 2px;
  height:36px;
  color:#fff;
  border: none;
  min-width: 63px;
  margin-right:5px;
}

.panelActionButton{
  border-radius: 2px;
  height:30px;
  color:#fff;
  border: none;
  padding-left:5px;
  padding-right:5px;

}

.formActionButtonSideBar{
  background-image: radial-gradient(50% 114%,
  #3DA6DB 50%, #008FD7 100%);
  border-radius: 2px;
  height:36px;
  color:#fff;
  border: none;
  min-width: 100px;
}

.formActionButtonContainer{
  margin-top:5px;
  margin-bottom:5px;
  text-align: center;
}

.formActionButtonContainerSmall{
  margin-top:5px;
  margin-bottom:5px;
  text-align: left;

}

.formStaticText{
  color:#fff;
  text-align: left;
  font-weight: normal;
  font-size:14px;
}

input[type='radio']{
  font-size: x-large;
  margin-right:3px;
}

option:first-child
{
  color: lightgray;
}

.bootstrap-datetimepicker-widget{
  background-color: #323232;
  color: lightgray;
  font-family:"Roboto",sans-serif;
  cursor: pointer;
}
.input-group-addon{
  background-color: #232323;
  cursor: pointer;
  border: none;
}

input.form-control {
  display: block;
  width: 100%;
  height: 30px;
  padding: 0px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

input.form-control::placeholder {
  color: #323232;
}

.searchServiceTextInput{
  width:65px;
  padding: 10px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.fieldFollowingDatepicker{
  margin-top:14px;
}

.formSelectContainer{
  margin-bottom: 4px;
}

.markerLinksContainer{
  height:500px;
  overflow-y:auto;
  overflow-x: hidden;
}

.searchresultitem{
  border-top: none;
  border-right: none;
  border-left: none;
  background-color:#323232;
  border-bottom-color: $mainHighlight;
  color:#fff;
  cursor: pointer;
}

.searchresultitemTitle{
  color:$mainHighlight;
  text-align: left;
}

button.searchresultitem:active .searchresultitemTitle{
  color:#fff;
}

button.searchresultitem:focus .searchresultitemTitle{
  color:#fff;
}

button.searchresultitem:hover .searchresultitemTitle{
  color:#fff;
}

.needCategoriesContainer{
  margin-top:0px;
  margin-bottom:35px;
}

button.searchresultitem:active{
  border-top: none;
  border-right: none;
  border-left: none;
  background-color:$mainHighlight;
  border-bottom-color: $mainHighlight;
  color:#fff;
}

button.searchresultitem:focus{
  border-top: none;
  border-right: none;
  border-left: none;
  background-color:$mainHighlight;
  border-bottom-color: $mainHighlight;
  color:#fff;
}

button.searchresultitem:hover{
  border-top: none;
  border-right: none;
  border-left: none;
  background-color:$mainHighlight;
  border-bottom-color: #323232;
  color:#fff;
}


.searchresultitemText{
  font-size:16px;
  color:#fff;
}


.infowindow{
  color:#323232;
}

@media print {
  .hidden-print {
    display: none !important;
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  #fileImageViewer {
    height: 840px;
  }
  .main-content {
    margin: 0;
    padding: 0;
  }
}

input[type='radio']{
  margin-top: 0;
  margin-right: 8px;
}

@media (max-width: 375px){
  .f7-s {
    font-size: .675rem;
  }
}

.rotateimg90 {
  -webkit-transform:rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotateimg270 {
  -webkit-transform:rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fit-to-parent {
  width:auto;
  height:auto;
  border: 0;
  top: 0; right: 0; bottom: 0; left: 0;
  margin: auto;
}

.pdfViewer {
  width: 100%;
  height: 60rem;
}

.flipX {
  transform: scaleX(-1);
}

.not-allowed {
  cursor: not-allowed;
}

.bottom-gray-fade-over {
  position: relative;
}

.bottom-gray-fade-over:after {
    content: "";
    position: absolute; top: 0; bottom: 0; left: -15px; right: -15px;
    box-shadow: inset $darker 0 -15px 15px;
}

form.rjsf .has-error   {
  color: #fff !important;
}
form.rjsf .help-block  {
  color: #fff !important;
}
form.rjsf .control-label  {
  color: #fff !important;
}
form.rjsf .text-danger {
  color: #EA2626;
}

.iframe-loading {
  background:url(/assets/images/loading.svg) center center no-repeat;
}

#fc_frame {
  transition: right .5s ease;
  display: none;
  right: -300px;
}
