//----- React Calendar -------//

.calendarContainer{
  background: $darker !important;
  height: 580px !important;
  margin-bottom: 1rem;
  position: relative;
}

.rbc-row-content{
  z-index: 0 !important;
}

@media (min-width: $screen-md-min) {
  .calendarContainer{
    padding-top: 0;
  }
}

@media (max-width: 386px){
  .rbc-toolbar button{
  }
}

@media (max-width: 452px){
  .rbc-toolbar button{
    font-size: 13px;
    padding: .2rem .3rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1051px) {
  .rbc-toolbar button{
    padding: 2px 6px 3px !important;
  }
}

.rbc-event{
  background: darken($mainHighlight,3);
  border-color: darken($mainHighlight,3);
  font-size: 12px;
  margin-bottom: 1px;
  margin-top: 1px;
  border-radius: 2px !important;
}

.rbc-toolbar-label{
  color: white;
}

.rbc-month-view{
  background: $dark;
  // color: white;
}

.rbc-month-view .rbc-header {
    border-bottom: 1px solid $darker !important;
}

.rbc-month-row+.rbc-month-row {
    border-top: 1px solid $darker !important;
}

.rbc-day-bg+.rbc-day-bg {
    border-left: 1px solid $darker !important;
}

.rbc-allday-cell .rbc-day-bg+.rbc-day-bg {
    border-left: 1px solid lighten($darker, 20) !important;
}

.rbc-rtl .rbc-day-bg+.rbc-day-bg {
    border-right: 1px solid $darker !important;
}

.rbc-date-cell{
  margin-top: .5rem;
  padding-right: .5rem;
}

.rbc-toolbar button{
  color:white !important;
}

.rbc-time-view{
  color:white;
  background: $dark;
}

.rbc-agenda-view{
  background: $darker;
}

.rbc-header{
  color:white;
}

.rbc-toolbar button:hover{
  background: darken($mainHighlight,3) !important;
}

.rbc-toolbar button:focus{
  background: darken($mainHighlight,3) !important;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background: darken($mainHighlight,3) !important;
}

.rbc-date-cell{
  color: $mainHighlight;
}

.rbc-month-view .rbc-header+.rbc-header{
  border-left: none !important;
}

.rbc-month-header{
  background: $darker;
}

.rbc-month-view{
  border: $darker !important;
}

.rbc-time-view{
  border: lighten($darker, 20) !important;
  background: $darker;
}

.rbc-time-header>.rbc-row:first-child {
  background: $darker;
}

.rbc-agenda-view thead{
  background: $darker;
}

// not sure where this
.rbc-day-slot .rbc-event {
    border: 1px solid $darker;
}

.rbc-time-slot+.rbc-time-slot {
    border-top: 1px solid lighten($darker, 20) !important;
}

.rbc-time-slot:nth-child(even) {
    border-top-color: lighten($darker, 20) !important;
}


.rbc-time-header.rbc-header-overflowing {
    // border-right: 1px solid lighten($darker, 20) !important;
}

.rbc-time-header>.rbc-row>*+*+* {
    border-left: 1px solid $darker !important;
}


.rbc-time-header>.rbc-row:first-child {
    // border-bottom: 1px solid $darker !important;
}

.rbc-time-content>*+*{
  border-left:1px solid lighten($darker, 20) !important;
}



@media (max-width: 330px) {
  .rbc-toolbar .rbc-toolbar-label {
    padding: 0 5px !important;
  }
}

@media (max-width: 344px) {
  .rbc-toolbar .rbc-toolbar-label {
    font-size: 10px
  }
  .rbc-toolbar-label{
    top: 37px !important;
  }
}

.rbc-toolbar{
  font-size: 13px !important;
  margin-bottom: 60px !important;
  text-align: center !important;
}

.rbc-date-cell.rbc-now {
  text-decoration: underline;
}

.rbc-current{
  background: $greenAlt;
  color: white !important;
  margin-bottom: 2px;
  position: relative;
}

.rbc-current:after {
  content: '';
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid $dark;
  clear: both;
}

.rbc-show-more{
  background-color: transparent !important;
  margin: 5px;
  font-size: 11px !important;
}

.rbc-event-content{
  text-transform: capitalize;
}

.rbc-btn-group:first-of-type{
  position: absolute;
  left: 0;
  top: 0;
}

.rbc-btn-group:last-of-type{
  position: absolute;
  right:0;
  top:0;
}

span.rbc-toolbar-label{
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto; 
  margin-right: auto;
  top: 33px !important;
  margin-bottom: .25rem;

}

.rbc-overlay{
  background-color: black !important;
  border: none !important;
}

.rbc-overlay-header{
  border: none !important;
  padding-top: 4px !important;
}

.rbc-off-range-bg {
  background-color: darken(#323232, 2) !important;
}

.rbc-today {
  background-color: #323232 !important;
}

.rbc-header.rbc-today {
  background-color: #323232 !important;
}

.rbc-day-slot.rbc-today {
    background-color: #323232 !important;
}

//----- ASIDE -------//

.calendar-aside-title{
  color:white;
  text-align: center;
  margin-bottom: 1rem;
}

.calendar-aside-items{
  height:350px;
  overflow: scroll;
}


.calendar-aside-item{
  color:white;
  background: $dark;
  padding: 12px;
  margin-bottom: 1rem;
  position: relative;
  transition: all 0.2s ease;
  // &:hover{
  //   background: lighten($dark,10);
  //   cursor: pointer;
  // }
}

.calendar-aside-item-pending {
  color:white;
  border: 1px dashed white;
  padding: 12px;
  margin-bottom: 1rem;
  position: relative;
  transition: all 0.2s ease;
}

.calendar-aside-options{
  position: absolute;
  top:0;
  right: .75rem;
  color: $mainHighlight;
  font-size: 2rem;
}

.aside-add-new-events{
  padding-top: 1rem;
  text-align: center;
  color: white;
  transition: all 0.2s ease;
  &:hover{
    color: $mainHighlight;
  }
}

.aside-add-new-events .glyphicon{
  font-size: 2rem;
}

.rbc-agenda-table tr {
  background-color: $darker !important;
}

@media
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {
  .calendarContainer{
    .rbc-agenda-time-cell{
      padding: 5px 10px !important;
    }
    .rbc-agenda-date-cell{
      color: $mainHighlight;
    }
  	/* Force table to not be like tables anymore */
  	table, thead, tbody, th, td, tr {
  		display: block;
  	}

  	/* Hide table headers (but not display: none;, for accessibility) */
  	thead tr {
  		position: absolute;
  		top: -9999px;
  		left: -9999px;
  	}

  	tr { border: 1px solid #ccc; }

  	td {
  		/* Behave  like a "row" */
  		border: none;
  		border-bottom: 1px solid #eee;
  		position: relative;
  		padding-left: 50%;
  	}

  	td:before {
  		/* Now like a table header */
  		position: absolute;
  		/* Top/left values mimic padding */
  		top: 6px;
  		left: 6px;
  		width: 45%;
  		padding-right: 10px;
  		white-space: nowrap;
    }
	}
}
