.contact-container{
  min-height: 60px;
  border-bottom: 1px solid grey;
  &:last-of-type{
    border-bottom: none;
  }
  &:hover{
    background: #424242;
  }
  padding: 0 20px;
}

a.contact-container:hover{
  color: $mainHighlight;
}

.contact-placeholder{
  height: 60px;
  width: 60px;
  color: white;
}

.contact-image{
  max-height: 60px;
  max-width: 60px;
  height: auto;
  width: auto;
}

.object-fit-cover{
  object-fit: cover;
}
