.top-nav{
  height: 44px;
  background: $dark;
  color: white;
  font-size: 14px;
  line-height: 44px;
  margin-bottom: 1em;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(23,23,23,0.25);
  -moz-box-shadow: 0px 0px 5px 1px rgba(23,23,23,0.25);
  box-shadow: 0px 0px 5px 1px rgba(23,23,23,0.25);
}

.brand-logo{
  color:white;
 //  display: block;
 // text-indent: -9999px;
 // width: 50px;
 // height: 52px;
 // background: url(../images/acivilate.png);
 // background-size: 50px 52px;
 &:hover{
   color:$mainHighlight;
 }
}

.nav-icon{
  font-size: 1.1em;
  top: 2px;
}

.user-icon{
  width: 30px;
}

.nav-right-elements{
  float:right;
}

.nav-right-elements li{
  margin-right: 1.5em;
  &:last-of-type{
    margin-right: 0;
    // for keeping logout btn from pushing nav below 44px
    height: 44px;
  }
}

.logout{
  // margin-right: 1.5rem;
}

.nav-icon-box{
  // padding: 0 1.5rem;
  // margin-right: 2rem;
  // color:white;
  &:hover{
    background: $mainHighlight;
  }
}

.nav-dropdown{
  background: none;
  box-shadow: none;
  color: white;
  border: 1px solid grey;
}

.btn-group-sm > .nav-dropdown.btn, .btn-sm {
  line-height: 0;
  padding: 1rem;
}
