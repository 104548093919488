.search-services-bar{
  width:75% !important;
}

@media (max-width: 348px){
  .search-services-bar{
    width: 55% !important;
  }
}

@media (min-width:349px) and (max-width: 543px){
  .search-services-bar{
    width: 60% !important;
  }
}

// .label-search-services{
//   cursor: pointer;
// }
