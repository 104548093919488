
.tabulator {
  background-color: transparent;
  border-radius: 0px;
}

.tabulator .tabulator-tableHolder .tabulator-table {
  background-color: white;
}

.tabulator-row .tabulator-cell {
  cursor: auto;
}

.tabulator .tabulator-header {
  background-color: transparent;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort=asc] .tabulator-col-content .tabulator-arrow {
  border-bottom: 6px solid $green;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort=desc] .tabulator-col-content .tabulator-arrow {
  border-top: 6px solid $green;
}

