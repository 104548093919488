.button {
  background: $greenAlt;
  color: white;
  border: none;
  transition: all 0.2s ease;
  text-shadow: none;
  border-radius: 2px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: none;
  &:hover {
    background: darken($greenAlt, 10);
    color: white;
  }
  &:active {
    background: darken($greenAlt, 10) !important;
    color: white !important;
  }
  &:focus {
    background: darken($greenAlt, 10);
    color: white;
  }
}

.Button:hover {
  opacity: 0.9;
}

.Button:disabled {
  opacity: 0.3;
  cursor: default !important;
}

.button-nav-top {
  background: transparent;
  padding: 3px;
  padding-right: 8px;
  border-radius: 0;
  display: inline;
  cursor: pointer;
  &:hover {
    background: darken($mainHighlight, 10);
  }
  &:active {
    background: darken($mainHighlight, 10) !important;
  }
  &:focus {
    background: darken($mainHighlight, 10) !important;
  }
}

// button icon in forms
.button-icon {
  display: inline-block;
}

.button-icon .glyphicon {
  font-size: 2.5rem;
  background: $dark;
  padding: 5px;
  border-radius: 2px;
  transition: all 0.2s ease;
  &:hover {
    background: $mainHighlight;
    color: white;
  }
  &:active {
    background: $mainHighlight;
    color: white;
  }
  a:focus {
    background: $mainHighlight;
    color: white;
  }
  a:visited {
    background: $mainHighlight;
    color: white;
  }
}

.button-view-notes {
  background: $mainHighlight;
  transition: all 0.2s ease;
  color: white;
  border-radius: 2px;
  padding: 0.25rem;
  padding-top: 0.5rem;
  padding-left: 0.7rem;
  font-size: 1.25rem;
  width: 229px;
  float: right;
  margin-bottom: 1rem;
  &:hover {
    background: darken($mainHighlight, 10);
  }
}

// search for services page

button.searchresultitem:focus {
  &:focus {
    background-color: $secondaryHighlight;
    border-bottom-color: $secondaryHighlight;
  }
}

// button for provider directory pageInfo

.button-create-new-service {
  margin: 0 auto;
  padding: 1rem 3rem;
  text-transform: uppercase;
  display: block;
  margin-top: 2rem;
  border-radius: 2px;
  .glyphicon {
    margin-right: 0.75rem;
  }
}

button.outline-btn {
  background: none !important;
  border: 1px solid $mainHighlight !important;
  color: $mainHighlight;
  text-shadow: none;
  &:hover {
    color: white;
    background: $mainHighlight !important;
    border: 1px solid $mainHighlight !important;
  }
  &:active {
    color: white !important;
    background: $mainHighlight !important;
    border: 1px solid $mainHighlight !important;
  }
  &:focus {
    color: white;
    background: $mainHighlight !important;
    border: 1px solid $mainHighlight !important;
  }
}

button.outline-btn-white {
  background: none !important;
  border: 1px solid $mainHighlight !important;
  color: white;
  text-shadow: none;
  &:hover {
    color: white;
    background: $mainHighlight !important;
    border: 1px solid $mainHighlight;
  }
  &:active {
    color: white;
    background: $mainHighlight !important;
    border: 1px solid $mainHighlight;
  }
  &:focus {
    color: white;
    background: $mainHighlight !important;
    border: 1px solid $mainHighlight;
  }
}

button.orange-btn {
  background: $secondaryHighlight !important;
  color: white;
  text-shadow: none;
  border: none;
  border-radius: 2px;
  padding: 0.75rem 1.5rem;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

  &:hover {
    background: darken($secondaryHighlight, 10) !important;
  }
  &:active {
    background: darken($secondaryHighlight, 10) !important;
  }
  &:focus {
    background: darken($secondaryHighlight, 10);
  }
}

button.blue-btn {
  background: $blue !important;
  color: white;
  text-shadow: none;
  border: none;
  border-radius: 2px;
  padding: 0.75rem 1.5rem;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  font-family: "Open Sans";
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    background: darken($blue, 10) !important;
  }
  &:active {
    background: darken($blue, 10) !important;
  }
  &:focus {
    background: darken($blue, 10);
  }
}

button.blue-btn:disabled {
  color: rgba(255, 255, 255, 0.5);
  background: desaturate($blue, 55) !important;
  &:hover {
    background: desaturate($blue, 55) !important;
  }
  &:active {
    background: desaturate($blue, 55) !important;
  }
  &:focus {
    background: desaturate($blue, 55) !important;
  }
}

.btn-red-outline {
  box-shadow: none;
  text-shadow: none;
  background: none;
  border: 1px solid red;
  color: white;
  border-radius: 2px;
  &:hover {
    background: red;
    color: white;
    border: 1px solid red;
  }
  &:active {
    background: red !important;
    color: white !important;
    border: 1px solid red !important;
  }
  &:focus {
    background: red;
    color: white;
    border: 1px solid red;
  }
}

.openDrawerButton {
  background-color: $danger;
  border: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.35);
  position: fixed;
  right: 15px;
  bottom: 80px;
  z-index: 5;
  &:hover {
    background-color: darken($danger, 10);
  }
}

@media (min-width: 992px) {
  .openDrawerButton {
    bottom: 15px;
  }
}

.side-drawer-btn {
  position: absolute;
  top: 3px;
  right: 10px;
}

button.close {
  font-size: 50px;
  position: absolute;
  right: 10px;
  top: -3px;
}

a.close {
  position: absolute;
  font-size: 50px;
  right: 7px;
  top: -15px;
}

.card-button {
  border: none;
  width: 100%;
  text-align: left;
  color: $mainHighlight;
  &:hover {
    background: #424242;
    color: $mainHighlight;
  }
}

.dropdown-menu {
  color: black;
}

.btn-no-style {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
