.left-nav-sidebar li a{
  border: 1px solid $mainHighlight;
  background: $dark;
  margin-bottom: .5rem;
  text-transform: uppercase;
  position: relative;
  &:hover{
    background: $mainHighlight;
    border-radius: none;
    color:white;
  }
  &:active{
    background: $mainHighlight;
    border-radius: none;
    color:white;
  }
  &:focus{
    background: $mainHighlight;
    border-radius: none;
    color:white;
  }
}

.left-nav-sidebar .glyphicon {
  font-size: 1.25rem;
  left: 1.1rem;
}

.left-nav-sidebar li a p {
  text-align: center;
}

@media (min-width: $screen-md-min){
  .left-nav-sidebar li a p {
    margin: 0;
    margin-left: 2rem;
    text-align: left;
  }

  .left-nav-sidebar .glyphicon {
    position: absolute;
    top: .5rem;
    left:inherit;
  }

  .left-nav-sidebar .glyphicon-briefcase {
    top: 1.25rem;
  }
}

@media (min-width: $screen-lg-min){
  .left-nav-sidebar .glyphicon {
    top: .4rem;
  }
  .left-nav-sidebar .glyphicon-briefcase {
    top: 1.25rem;
  }
}


//---------- RIGHT SIDE BAR ----------------//

.right-nav-sidebar{
  background: $dark;
  border: none;
}

// new

.left-sidebar{
  top: 96px;
}

.sidebar-item {
  background: $dark;
  color: white;
  padding: 1.5rem;
  border: 1px solid $mainHighlight;
  border-bottom: none;
  &:hover{
    color:white;
    background: $mainHighlight;
  }
  &:active{
    color:white;
    background: $mainHighlight;
  }
  &:focus{
    color:white;
    background: $mainHighlight;
  }
}
//
// @media (min-width: $screen-md-min) {
//   .sidebar-item {
//     width: 131px;
//   }
// }
//
// @media (min-width: 1200px){
//   .sidebar-item {
//     width: 165px;
//   }
// }

.sidebar-item-active{
  color:white;
  background: $mainHighlight;
}

.sidebar-item:first-child{
  border-radius: 4px 4px 0 0;
}

.sidebar-item:last-child{
  border-bottom: 1px solid $mainHighlight;
  border-radius: 0 0 4px 4px;
}

.sidebar-item .glyphicon {
  margin-right: 1rem;
  font-size: 2rem;
}

.sidebar-item p {
  margin: 0;
}
