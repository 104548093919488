.bottom-nav{
  height: 65px;
  background: $dark;
  color: white;
  font-size: 14px;
  // line-height: 70px;
  position: fixed;
  bottom: 0;
  width: 100%;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(23,23,23,0.25);
  -moz-box-shadow: 0px 0px 5px 1px rgba(23,23,23,0.25);
  box-shadow: 0px 0px 5px 1px rgba(23,23,23,0.25);
  z-index: 5;
}

.bottom-nav p {
  margin: 0;
  font-size: 1.1rem;
}

.bottom-nav .glyphicon {
  font-size: 2rem;
  margin-bottom: .5rem;
}

.bottom-nav a {
  color:white;
  &:hover{
    color: $mainHighlight;
  }
}

.bottom-nav .bottom-nav-active {
  color:$mainHighlight;
}

.bottom-nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: $dark;
    text-align: center;
    li {
        display: inline;
    }

    li a {
        display: inline-block;
        color: white;
        text-align: center;
        padding: 0 7px;
        text-decoration: none;
        position: relative;
    }

    li a:hover {
        background-color: $mainHighlight;
    }
}

@media screen and (min-width: 375px){
  .bottom-nav ul li a {
    padding: 0 10px;
  }
}

.bottom-nav ul span.glyphicon{
  position: absolute;
  top:15px;
  left: 33%;
  font-size: 1.4em;
}
.bottom-nav ul span.glyphicon-folder-open{
  left: 15%;
}
.bottom-nav ul span.glyphicon-list-alt{
  left: 15%;
}
.bottom-nav ul span.glyphicon-user{
  left: 30%;
}

.bottom-nav ul p{
  padding-top: 12px;
  font-size: .75em;
}

@media screen and (min-width: 400px){
  .bottom-nav ul li a {
    padding: 0 15px;
  }
  .bottom-nav ul span.glyphicon-folder-open{
    left: 25%;
  }
  .bottom-nav ul span.glyphicon-list-alt{
    left: 25%;
  }
  .bottom-nav ul span.glyphicon-user{
    left: 35%;
  }
}

@media (min-width:768px){
  .bottom-nav-padding{
    padding: 8px;
    padding-right:30px;
    padding-left:30px;
  }
}
