$green: #90c42f;
$darkerGreen: #7aad1d;
$brandOrange: #f0a815;
$blue: #1790d4;
$orange: #E28413;

$dark: #323232;
$darker: #232323;
$lighter: #a6a6a6;

$mainHighlight: $green;
$secondaryHighlight: $orange;

$greenAlt: #1EAE4E;
$warning: #d09a14;
$danger: #DE1A1A;
$info: #60b49a;
$primary: #2274A5;
$darkMagenta: #AF1B3F;
$darkTeal: #136F63;
