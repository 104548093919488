@mixin tpLabel {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: $secondaryHighlight;
  letter-spacing: 2.5px;
}

@mixin tpDescription {
  padding-right: 0.5rem;
}

.GoalsListItem {
  background-color: $dark-grey;
  margin-bottom: 1rem;
  padding: 1rem;
}

.GoalsListItem__description {
  font-weight: bold;
  color: $green;
}

// TreatmentPlan
.TreatmentPlan {
  color: white;
}

.TreatmentPlan__Goal {
  // max-width: 66.66%;
  background-color: $dark-grey;
  padding: 1rem;
  margin-bottom: 1rem;
  li {
    margin-bottom: 1rem;
  }
}

.TreatmentPlan__GoalHeader {
  display: block;
  font-size: 2.5rem;
  margin: 0;
}

.TreatmentPlan__detailsLink {
  display: block;
}

.TreatmentPlan__expand {
  cursor: pointer;
  text-decoration: underline;
}

// Goal > Objective > Task hierarchy
$objectives-bg-color: green;
.Objectives {
  // background-color: $objectives-bg-color;
  // padding: 1rem;
}

// container for objective description and its task list
.Objective {
  padding: 1rem;
  background-color: $dark-grey;
  border-radius: 5px;
  margin-bottom: 1rem;

  // caret that hides/shows tasks under objective
  &__tasksToggle {

    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
    margin-top: .5rem;
    margin-bottom: .5rem;
    cursor: pointer;
  }

  &__description {
    @include tpDescription;
  }

  &__label {
    @include tpLabel;
  }

  &__newTask {
    margin: 0 auto;
    width: 66.66%;
    border-radius: 5px;
    background-color: $green;
    color: white;
    margin-top: 1rem;
    padding: 0.5rem;
  }

  &__delete {
    color: red;
  }

  &__actions {
    align-self: flex-start;
  }
}

.add-item-spacer{
  padding-top:8px;
  padding-bottom: 8px;
}
