.participantCardListContainer{
  text-align: center;
  /*margin-left:60px;*/
  margin-top:50px;
  width:85%;
  margin-left:auto;
  margin-right:auto;
  height: 660px;
  overflow: auto;
  overflow-x: hidden;
}

.serviceProviderListContainer{
  text-align: center;
  /*margin-left:60px;*/
  margin-top:2rem;
  width:80%;
  margin-left:auto;
  margin-right:auto;
  height:650px;
  overflow-y: auto;
  overflow-x: hidden;
}

.participantCardContainer{
  text-align: center;
  width:150px;
  margin-bottom: 35px;
}

.participantCard{
  background: #393939;
  border: 1px solid $green;
  border-radius: 2px;
  font-family: 'Oswald', sans-serif;
  height:150px;
  width: 150px;
  margin-left:5px;
  display: table;
}

.participantCardImage{
  width:150px;
  height:150px;
}

.participantCardTitle{
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  color: #fff;
  text-align: center;
  transition: all 0.2s ease;
  &:hover{
    color: $mainHighlight;
  }
}


.profileCardContainer{
  text-align: center;
  width:150px;
}

.profileCard{
  background: #393939;
  border: 1px solid $green;
  border-radius: 2px;
  font-family: 'Oswald', sans-serif;
  height:156px;
  width: 140px;
  margin-left:5px;
  display: table;
}

.profileImageCard{
  background: #393939;
  border: 1px solid $green;
  border-radius: 2px;
  font-family: 'Oswald', sans-serif;
  color:#fff;
  border-radius: 2px;
  height:156px;
  width: 140px;
  font-family: 'Oswald', sans-serif;
  margin-left:5px;
  /*margin-top:25px;*/
  cursor: pointer;
  /*padding: 5px;*/
  display: table;
}

.profileImageCardText{
  display: table-cell;
  vertical-align: middle;
}

.profileCardTitle{
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin: 5px 14px 5px 14px;
  display: table-cell;
  vertical-align: middle;


}

.profileCardProgressIndicatorText{
  font-family: 'Oswald', sans-serif;
  font-size: 10px;
  color:#fff;
  padding-top:5px;

}

.profileCardIconText{
  font-family: 'Oswald', sans-serif;
  font-size: 6px;

}

.profileCardIconContainer{
  text-align: center;
  width:150px;
  margin-top:5px;
}

.profileCardIcon{
  width: 25px;
  font-weight: 20px;
  margin: 5px;

  /*background-color:  $green;*/

}

.profileCardShareIcon
 {
    margin-left: 10px;
    margin-right: 0px;
}

.profileCardPrintIcon
{
    margin-left: 0px;
    margin-right: 10px;
}

.profileCardDownloadIcon
{
    margin-left: 10px;
    margin-right: 10px;
}

.profilePanelIconContainer{
  text-align: left;
  margin:5px;


}

.profilePanelIcon{
  width: 20px;
  font-weight: 20px;
  margin: 5px;
  /*background-color:  $green;*/

}


.profileCardCompletionContainer{
  background: #FFFFFF;
  border-radius: 2px;
  width:150px;
  height:20px;
  top: 120px;
  position: relative;



}

.profileCardCompletionIndicator{
  background: $green;
  border-radius: 2px;
  height:16px;
  position: relative;
  top:2px;
  margin:5px;
  width:93%;

  vertical-align: middle;

}

.profileCardListContainer{
  text-align: center;
  /*margin-left:60px;*/
  margin-top:50px;
  width:80%;
  margin-left:auto;
  margin-right:auto;
}
