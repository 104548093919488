.acivilate-modal{
  background: $darker;
  border: 2px solid $mainHighlight;
}

.acivilate-modal .modal-header{
  background: $mainHighlight;
  color: white;
  border-bottom: none;
}

.acivilate-modal .modal-body{
  color: white;
}

.acivilate-modal .modal-footer{
  color: white;
  border-top: none;
}

.modal-calendar{
    width: 300px;
    margin: 30px auto;
}


@media (min-width: 768px){
  .modal-400px-max-width .modal-dialog{
    max-width: 400px;
  }
}

.pokket-modal{
  padding-right: 0 !important;
}

.pokket-modal .modal-content{
  background: $dark;
  border: 2px solid $mainHighlight;
}

.pokket-modal .modal-header{
  background: $mainHighlight;
  color: white;
  border-bottom: none;
  text-align: center;
  padding: 8px;
}

.pokket-modal .modal-header .modal-title{
  font-weight: 600;
}

.modal-title-editable{
  background: $dark;
  border-radius: 4px;
  padding: 2px;
}

.pokket-modal .modal-body{
  color: white;
}

.pokket-modal .modal-footer{
  color: white;
  border-top: none;
  padding-top: 0;
}

.pokket-modal button{
  background: $mainHighlight;
  border:none;
  border-radius: 4px;
  font-weight: 600;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  &:hover{
    background: darken($mainHighlight,10);
  }
  &:active{
    background: darken($mainHighlight,10);
  }
}

// task detail

.task-detail-list > .Flex{
  margin-bottom: 1rem;
  border-bottom: 1px solid darken(grey,20);
  padding-bottom: 1rem;
}

.task-detail-list > .Flex:last-of-type{
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}


.task-detail-list .glyphicon{
  margin-right: 1rem;
}

.task-detail-inactive{
  text-align: right;
}

.modal-footer-button{
  padding: 7.5px 15px;
}

.drop-zone{
  padding: 1rem 2rem;
  border: 3px dashed darken(grey,10);
  border-radius: 1rem;
  cursor: pointer;
  &:hover{
    border-color: white;
  }
}

.drop-zone-copy{
  text-indent: -9999px;
 line-height: 0; /* Collapse the original line */
 color:transparent;
 text-align: center;
}

.drop-zone-copy:before{
  content: "Add files";
  text-indent: 0;
  display: block;
  line-height: initial;
  color: white;
}

@media screen and (min-width: 768px) {
  .drop-zone-copy:before{
    content: "Click or drag and drop to add files"
  }
}

.task-detail-options button{
  background: none !important;
  box-shadow: none;
  text-shadow: none;
  color: $mainHighlight !important;
  border: 2px solid $mainHighlight !important;
  &:hover{
    background: $mainHighlight !important;
    color: white !important;
    border: 2px solid $mainHighlight !important;
  }
  // &:active{
  //   background: $mainHighlight !important;
  //   color: white !important;
  //   border: 2px solid $mainHighlight !important;
  //   box-shadow: none !important;
  // }
  // &:focus{
  //   background: $mainHighlight !important;
  //   color: white !important;
  //   border: 2px solid $mainHighlight !important;
  //   box-shadow: none !important;
  // }
}

.task-detail-options > ul > li > a {
  padding-top: 1.05rem;
  padding-bottom: 1.05rem;
}

.delete-outline{
  -webkit-box-shadow:inset 0px 0px 0px 2px #f00;
  -moz-box-shadow:inset 0px 0px 0px 2px #f00;
  box-shadow:inset 0px 0px 0px 2px #f00;
  background:none !important;
  &:hover{
    background: red !important;
  }
  &:active{
    background: red !important;
  }
}

.task-detail-datepicker .input-group.date {
  float:right;
  width: 60%;
}

.task-detail-datepicker .glyphicon{
  margin-right: 0;
  color: grey;
}

.task-detail-time-selector .glyphicon{
  margin-right: 0;
  color: grey;
}

.task-detail-time-selector .input-group{
  width: 100%;
}

.task-detail-time-selector .input-group-addon {
  padding:5px;
}

.task-detail-time-selector button {
  &:hover{
    background: darken($mainHighlight,10) !important;
  }
  &:active{
    background: darken($mainHighlight,10) !important;
  }
  &:focus{
    background: darken($mainHighlight,10) !important;
  }
}

.timepicker-hour, .timepicker-minute, .hour, .minute {
  &:hover{
    color: $dark !important;
  }
}

.timepicker-hour {
  &:hover{
    color: $dark !important;
  }
}

.bootstrap-datetimepicker-widget td.today:before{
  border-bottom: 7px solid $mainHighlight !important;
}

.bootstrap-datetimepicker-widget thead tr:first-child th {
  color: $mainHighlight !important;
}


.bootstrap-datetimepicker-widget td.active, .bootstrap-datetimepicker-widget td.active:hover {
  background-color: $mainHighlight !important;
}

.bootstrap-datetimepicker-widget td span:hover{
  background-color: $mainHighlight !important;
  color:white;
}

.bootstrap-datetimepicker-widget td.day:hover, .bootstrap-datetimepicker-widget td.hour:hover, .bootstrap-datetimepicker-widget td.minute:hover, .bootstrap-datetimepicker-widget td.second:hover {
  background-color: $mainHighlight !important;
  color:white;
}

.bootstrap-datetimepicker-widget td span.active {
   background-color: $mainHighlight !important;
}

.timeout-alert{
  background-image: none;
  background-color: #323232 !important;
  border-color: $mainHighlight;
  color:white;
}
